const getters = {
  id: state => state.user.id,
  token: state => state.user.token,
  roleMenu: state => state.user.roleMenu,
  userInfo: state => state.user.userInfo,
  realName: state => state.user.realName,
  userType: state => state.user.userType,
  buttonPermission: state => state.user.buttonPermission,
  areas: state => state.base.areas,
  collapse: state => state.base.collapse,
  cachedViews: state => state.navBar.cachedViews,
}
export default getters