import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/layout/index.vue";
import Login from "@/views/login/index.vue";

// import Home from "@/views/home/index.vue"
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };
Vue.use(VueRouter);

const routes = [
  {
    id: "1",
    path: "/layout",
    name: "Layout",
    component: Layout,
    hidden: false,
    redirect: '/layout/home',
    children: [
      {
        id: "11",
        path: "/layout/home",
        title: "首页",
        name: "Home",
        icon: "el-icon-s-home",
        meta: { keepAlive: false },
        hidden: false,
        component: () => import("@/views/dashboard/index")
      }
    ],
  },
  {
    id: "2",
    path: "/tenant",
    title: "租户管理",
    name: "Tenant",
    icon: "el-icon-s-order",
    hidden: false,
    component: Layout,
    children: [
      {
        id: "21",
        path: "/tenant/management",
        title: "租户管理",
        name: "tenant-management",
        icon: "el-icon-s-management",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/manage/tenant"),
      }
    ]
  },
  {
    id: "3",
    path: "/user",
    name: "User",
    title: "账号管理",
    icon: "el-icon-user-solid",
    hidden: false,
    component: Layout,
    children: [
      {
        id: "31",
        path: "/user/management",
        title: "账号管理",
        name: "UserManagement",
        icon: "el-icon-user-solid",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/manage/sysUser.vue"),
      },
      {
        id: "32",
        path: "/role",
        title: "角色管理",
        name: "RoleManagement",
        icon: "el-icon-s-tools",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/manage/role.vue"),
      },
    ]
  },
  {
    id: "4",
    path: "/company",
    name: "Company",
    title: "土方单位管理",
    icon: "el-icon-star-on",
    hidden: false,
    component: Layout,
    children: [
      {
        id: "41",
        path: "/company/management",
        title: "土方单位管理",
        name: "companyManagement",
        icon: "el-icon-star-on",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/manage/company.vue"),
      },
    ]
  },
  {
    id: "7",
    path: "/project",
    name: "Project",
    title: "工地管理",
    icon: "el-icon-map-location",
    hidden: false,
    component: Layout,
    children: [
      {
        id: "71",
        path: "/project/management",
        title: "工地管理",
        name: "projectManagement",
        icon: "el-icon-setting",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/project/project.vue"),
      },
      {
        id: '72',
        path: '/project/order',
        title: '工地工单',
        name: 'projectOrder',
        hidden: false,
        icon: 'el-icon-s-order',
        // meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/project/projectOrder.vue')
      },
      {
        id: '145',
        path: '/project/config',
        title: '配置管理',
        hidden: true,
        name: 'projectConfig',
        icon: 'el-icon-edit-outline',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/project/config.vue')
      },
      {
        id: "146",
        path: "/project/video",
        title:'实时视频',
        name: "Video",
        component: () => import("@/views/project/video.vue"),
        hidden: true
      },{
        id: "147",
        path: "/line/management",
        title: "线路管理",
        name: "lineManagement",
        icon: "el-icon-setting",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/line/line.vue"),
      },{
        id: "148",
        path: "/car/management",
        title: "车辆管理",
        name: "CarManagement",
        icon: "el-icon-setting",
        meta: { keepAlive: false },
        hidden: false,
        component: () => import("@/views/car/car.vue"),
      },
      {
        id: "149",
        path: "/alarm/management",
        title: "设备消息",
        name: "AlarmManagement",
        icon: "el-icon-setting",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/alarm/alarm.vue"),
      },
      {
        id: "150",
        path: "/eventNotify/index",
        title: "告警设备消息",
        name: "EventNotify",
        icon: "el-icon-setting",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/eventNotify/index.vue"),
      },
    ]
  },
  {
    id: "6",
    path: "/device",
    name: "Device",
    title: "设备管理",
    icon: "el-icon-setting",
    hidden: false,
    component: Layout,
    children: [
      {
        id: "61",
        path: "/device/management",
        title: "设备管理",
        name: "companyManagement",
        icon: "el-icon-setting",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/manage/device.vue"),
      },
      // {
      //   id: "62",
      //   path: "/company/management",
      //   title: "设备发放",
      //   name: "companyManagement",
      //   icon: "el-icon-setting",
      //   meta: { keepAlive: true },
      //   hidden: false,
      //   component: () => import("@/views/manage/company.vue"),
      // },
    ]
  },
  {
    id: "141",
    path: "/recycleOrder",
    name: "RecycleOrder",
    title: "消纳场管理",
    icon: "el-icon-setting",
    hidden: false,
    component: Layout,
    children: [
      {
        id: "141",
        path: "/recycleOrder/management",
        title: "消纳场工单",
        name: "companyManagement",
        icon: "el-icon-setting",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/recycle/recycleOrder.vue"),
      },
      // {
      //   id: "62",
      //   path: "/company/management",
      //   title: "设备发放",
      //   name: "companyManagement",
      //   icon: "el-icon-setting",
      //   meta: { keepAlive: true },
      //   hidden: false,
      //   component: () => import("@/views/manage/company.vue"),
      // },
    ]
  },
  {
    id: "142",
    path: "/ProvinceBill",
    name: "ProvinceBill",
    title: "省平台联单",
    icon: "el-icon-setting",
    hidden: false,
    component: Layout,
    children: [
      {
        id: "142",
        path: "/province/management",
        title: "省平台联单",
        name: "companyManagement",
        icon: "el-icon-setting",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/provinceBill/bill.vue"),
      },
      // {
      //   id: "62",
      //   path: "/company/management",
      //   title: "设备发放",
      //   name: "companyManagement",
      //   icon: "el-icon-setting",
      //   meta: { keepAlive: true },
      //   hidden: false,
      //   component: () => import("@/views/manage/company.vue"),
      // },
    ]
  },
  {
    id: '7',
    path: '/layout/routing',
    title: '车辆追踪',
    name: 'Layout',
    hidden: true,
    component: Layout,
    children: [
      {
        id: '71',
        path: '/layout/routing',
        title: '车辆追踪',
        name: 'MapGJ',
        hidden: true,
        icon: 'el-icon-s-home',
        component: () => import('@/views/projectAmap/routing')
      }
    ]
  },
  {
    id: "5",
    path: "/log",
    name: "Log",
    title: "日志管理",
    icon: "el-icon-info",
    hidden: false,
    component: Layout,
    children: [
      {
        id: "51",
        path: "/operation/log",
        title: "操作日志",
        name: "OperationLog",
        icon: "el-icon-warning",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/manage/operationLog.vue"),
      },
      {
        id: "52",
        path: "/login/log",
        title: "登录日志",
        name: "OperationLog",
        icon: "el-icon-warning",
        meta: { keepAlive: true },
        hidden: false,
        component: () => import("@/views/manage/loginLog.vue"),
      },
    ]
  },
  {
    id: "35",
    path: "/common",
    name: "Common",
    component: Layout,
    hidden: true,
    children: [
      {
        id: "10",
        path: "/common/emptyPage",
        title: "空白页面",
        name: "EmptyPage",
        icon: "el-icon-s-home",
        hidden: true,
        component: () => import(/*webpackChunkName: "emptyPage" */ "@/views/empty-page/index.vue"),
      },
    ]
  },
  {
    id: "1",
    path: "/",
    redirect: "login",
    hidden: true
  },
  {
    id: "2",
    path: "/login",
    name: "Login",
    component: Login,
    hidden: true
  },

];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
// 全局守卫
// router.beforeEach((to, from, next) => {
//   //todos:判断是否有token,有继续跳转，无跳转至登陆页
// });
export default router;
export { routes };
