<!--
 * @Author: xiaoxingliang
 * @Date: 2021-12-18 10:35:18
 * @LastEditTime: 2021-12-18 16:55:26
 * @LastEditors: xiaoxingliang
 * @FilePath: \lc_factory\src\App.vue
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    // console.log(process.env)
  }
};
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
