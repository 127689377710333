const base = {
  state: {
    areas: [],
    // feat: 消息中心
    news: [],
    isNewsAllRead: false,
    collapse: false
  },

  mutations: {
    SET_AREAS: (state, areas) => {
      state.areas = areas
    },
    // feat: 消息中心
    SET_NEWS: (state, news) => {
      state.news = news
    },
    SET_ISNEWSALLREAD: (state, data) => {
      state.isNewsAllRead = data
    },
    SET_COLLAPSE: (state, data) => {
      state.collapse = data
    }
  },
  actions: {
  }
}

export default base
