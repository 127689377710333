import dayjs from 'dayjs';
import CryptoJS from 'crypto-js'
/*
 * 根据时间戳生成随机数
 */
export const getNum = () => {
  const time = new Date().getTime() || Date.now();
  return (
    time.toString(36) +
    Math.random()
      .toString(36)
      .slice(-1)
  );
};

/**
 * @method 防抖函数
 * @param {string} key 定时的值
 * @param {number} delay 多少秒执行一次时间
 */
export const debounceWork = (key, delay = 300) => {
  let prevTime = sessionStorage.getItem(key);
  let now = new Date().getTime();

  if (!prevTime) {
    sessionStorage.setItem(key, now);
    return true;
  } else {
    if (now > Number(prevTime) + delay) {
      sessionStorage.setItem(key, now);
      return true;
    }
    return false;
  }
};
// 取到当月最后一天
export const getLatestDate = lastDate => {
  let year, month, date
  const today = new Date(lastDate)
  year = today.getFullYear()
  month = today.getMonth() + 1
  switch (month) {
    case 1:
      date = 31;
    case 3:
      date = 31;
    case 5:
      date = 31;
    case 7:
      date = 31;
    case 8:
      date = 31;
    case 10:
      date = 31;
    case 12:
      date = 31;
      break;
    case 4:
      date = 30;
    case 6:
      date = 30;
    case 9:
      date = 30;
    case 11:
      date = 30;
      break;
    case 2:
      if(year % 400 === 0 || (year % 400 !== 0 && year % 4 === 0) ) {
        date = 29
      } else {
        date = 28
      }
      break;
  }
  return `${year}-${month < 10 ? '0' + month : month}-${date = date < 10 ? '0' + date : date}`
}
// 时间选择器的快键键
export const shortcuts = [
  {
    text: '今天',
    onClick(picker) {
      const now = new Date();
      picker.$emit('pick', [`${dayjs(now).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(now).format('YYYY-MM-DD')} 23:59:59`]);
    }
  },
  {
    text: '本月',
    onClick(picker) {
      const end = new Date();
      let start = new Date();
      let year, month, date
      year = start.getFullYear()
      month = start.getMonth() < 10 ? ( '0' + (start.getMonth() + 1)) : (start.getMonth() + 1)
      date = '01'
      start = `${year}-${month}-${date}`
      picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
    }
  },
  {
    text: '上月',
    onClick(picker) {
      let end = new Date();
      let start = new Date();
      let year, month, date
      if(start.getMonth() === 0) {
        year = start.getFullYear() - 1
        month = 12
        date = '01'
        start = `${year}-${month}-${date}`
        end = getLatestDate(`${year}-${month}-${date}`)
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
      } else {
        year = start.getFullYear()
        month = start.getMonth() < 10 ? ( '0' + start.getMonth()) : start.getMonth()
        date = '01'
        start = `${year}-${month}-${date}`
        end = getLatestDate(`${year}-${month}-${date}`)
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
      }
    }
  },
  {
    text: '最近一周',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
      picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
    }
  },
  {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date();
      let start = new Date();
      start.setTime(start.getTime() + 24 * 3600 * 1000);
      let year, month, date
      if(start.getMonth() === 0) {
        year = start.getFullYear() - 1
        month = 12
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
      } else {
        year = start.getFullYear()
        month = start.getMonth() < 10 ? ( '0' + (start.getMonth())) : start.getMonth()
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
      }
    }
  },
  {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date();
      let start = new Date();
      start.setTime(start.getTime() + 24 * 3600 * 1000);
      let year, month, date
      if(start.getMonth() - 3 < 0) {
        year = start.getFullYear() - 1
        month = 10 + start.getMonth()
        month = month < 10 ? '0' + month : month
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
      } else {
        year = start.getFullYear()
        month = start.getMonth() < 10 ? ( '0' + (start.getMonth() - 2)) : (start.getMonth() - 2)
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
      }
    }
  },
  {
    text: '最近六个月',
    onClick(picker) {
      const end = new Date();
      let start = new Date();
      start.setTime(start.getTime() + 24 * 3600 * 1000);
      let year, month, date
      if(start.getMonth() - 6 < 0) {
        year = start.getFullYear() - 1
        month = 7 + start.getMonth()
        month = month < 10 ? '0' + month : month
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
      } else {
        year = start.getFullYear()
        month = start.getMonth() < 10 ? ( '0' + (start.getMonth() - 5)) : (start.getMonth() - 5)
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
      }
    }
  },
  {
    text: '最近一年',
    onClick(picker) {
      const end = new Date();
      let start = new Date();
      start.setTime(start.getTime() + 24 * 3600 * 1000);
      let year, month, date
      year = start.getFullYear() - 1
      month = start.getMonth() < 10 ? ( '0' + (start.getMonth() + 1)) : (start.getMonth() + 1)
      date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
      start = `${year}-${month}-${date}`
      picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')} 00:00:00`, `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`]);
    }
  },
]
// 月份选择器的快键键
export const monthShortcuts = [
  {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date();
      let start = new Date();
      let year, month, date
      if(start.getMonth() === 0) {
        year = start.getFullYear() - 1
        month = 12
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')}`, `${dayjs(end).format('YYYY-MM-DD')}`]);
      } else {
        year = start.getFullYear()
        month = start.getMonth() < 10 ? ( '0' + (start.getMonth())) : start.getMonth()
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')}`, `${dayjs(end).format('YYYY-MM-DD')}`]);
      }
    }
  },
  {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date();
      let start = new Date();
      let year, month, date
      if(start.getMonth() - 3 < 0) {
        year = start.getFullYear() - 1
        month = 10 + start.getMonth()
        month = month < 10 ? '0' + month : month
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')}`, `${dayjs(end).format('YYYY-MM-DD')}`]);
      } else {
        year = start.getFullYear()
        month = start.getMonth() < 10 ? ( '0' + (start.getMonth() - 2)) : (start.getMonth() - 2)
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')}`, `${dayjs(end).format('YYYY-MM-DD')}`]);
      }
    }
  },
  {
    text: '最近六个月',
    onClick(picker) {
      const end = new Date();
      let start = new Date();
      let year, month, date
      if(start.getMonth() - 6 < 0) {
        year = start.getFullYear() - 1
        month = 7 + start.getMonth()
        month = month < 10 ? '0' + month : month
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')}`, `${dayjs(end).format('YYYY-MM-DD')}`]);
      } else {
        year = start.getFullYear()
        month = start.getMonth() < 10 ? ( '0' + (start.getMonth() - 5)) : (start.getMonth() - 5)
        date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
        start = `${year}-${month}-${date}`
        picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')}`, `${dayjs(end).format('YYYY-MM-DD')}`]);
      }
    }
  },
  {
    text: '最近一年',
    onClick(picker) {
      const end = new Date();
      let start = new Date();
      let year, month, date
      year = start.getFullYear() - 1
      month = start.getMonth() < 10 ? ( '0' + (start.getMonth() + 1)) : (start.getMonth() + 1)
      date = start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()
      start = `${year}-${month}-${date}`
      picker.$emit('pick', [`${dayjs(start).format('YYYY-MM-DD')}`, `${dayjs(end).format('YYYY-MM-DD')}`]);
    }
  },
]
// 取最近的一个星期
export const latestWeek = [
  dayjs(new Date().getTime() - 1000 * 60 * 60 * 24 * 6).format(
    'YYYY-MM-DD'
  ) + ' 00:00:00',
  dayjs(new Date()).format('YYYY-MM-DD') + ' 23:59:59',
]

// 取当月的收尾日期
export const latestMonth = () => {
  let now = new Date();
  let year, month, date
  year = now.getFullYear();
  month = now.getMonth() + 1;
  return [
    `${year}-${month < 10 ? '0' + month : month}-01`,
    getLatestDate(dayjs(new Date()).format('YYYY-MM-DD')),
  ]
}


/**
 * 日期时间选择器的范围disabledDate 最近一年
 * @param {date} time
 * @returns {boolean}
 */
export const disabledDate = time => {
  return time.getTime() > new Date(dayjs(new Date()).format('YYYY-MM-DD') + ' 23:59:59').getTime() || time.getTime() < Date.now() - 1000 * 3600 * 24 * 366;
}
/**
 * 删除空参数
 * @param {Object} params
 * @returns
 */
export const isParamsNotNull = params => {
  if(!params) return {}
  Object.entries(params).forEach(([key, value], index) => {
    if(value === '' || value === null || value === undefined) {
      delete params[key]
    }
  });
  return params
}
const devSecretKey = 'QAZWSX123456789qazwsxedcrfvtgbyh'
/**
 * AES-256-ECB对称加密
 * @param text {string} 要加密的明文
 * @param secretKey {string} 密钥，43位随机大小写与数字
 * @returns {string} 加密后的密文，Base64格式
 */
export function AES_ECB_ENCRYPT(text, secretKey = devSecretKey) {
  var keyHex = CryptoJS.enc.Base64.parse(secretKey);
  var messageHex = CryptoJS.enc.Utf8.parse(text);
  var encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
    "mode": CryptoJS.mode.ECB,
    "padding": CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}

/**
 * AES-256-ECB对称解密
 * @param textBase64 {string} 要解密的密文，Base64格式
 * @param secretKey {string} 密钥，43位随机大小写与数字
 * @returns {string} 解密后的明文
 */
export function AES_ECB_DECRYPT(textBase64, secretKey = devSecretKey) {
  var keyHex = CryptoJS.enc.Base64.parse(secretKey);
  var decrypt = CryptoJS.AES.decrypt(textBase64, keyHex, {
    "mode": CryptoJS.mode.ECB,
    "padding": CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
