<!--
 * @Author: xiaoxingliang
 * @Date: 2021-12-20 13:52:15
 * @LastEditTime: 2021-12-23 16:18:05
 * @LastEditors: xiaoxiangliang
 * @FilePath: /lc_factory/src/views/layout/components/NavBar.vue
-->
<template>
  <div class="nav-bar" v-show="visitedViews.length">
    <div
      class="bar-item"
      v-for="(item,index) in showBarList"
      :class="{active_menu:activeMenuId==item.id?true:false,'hover-background':item.id==hoverBarId?true:false}"
      :key="index"
      @click="barItemClick(item)"
      @mouseenter="handleMouseenter(item)"
      @mouseleave="handleMouseleave"
    >
      <div class="content">
        <span
          class="refresh"
          v-show="item.id==activeMenuId&&item.id==hoverBarId"
          @click.stop="refreshPage(item)"
        >
          <i class="el-icon-refresh-right"></i>
        </span>
        <span class="bar-text">{{item.title}}</span>
        <span class="close" v-show="item.id==hoverBarId" @click.stop="closeBarItem(item)">
          <i class="el-icon-close"></i>
        </span>
      </div>
    </div>
    <el-dropdown
      v-show="visitedViews.length > 8"
      class="dropdownMore"
      trigger="click"
      @command="handleCommand"
    >
      <span class="el-dropdown-link">
        更多
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="item in fixBarList"
          :key="item.url"
          :command="item"
          :divided="item.divided"
        >{{ item.title }}</el-dropdown-item>
        <div
          class="el-dropdown-menu__item hidden-item"
          v-for="item in hiddenBarList"
          :key="item.url"
          :class="{
            active_menu:activeMenuId==item.id?true:false,
            'hover-background':item.id==hoverBarId?true:false
          }"
          @mouseenter="handleMouseenter(item)"
          @mouseleave="handleMouseleave"
        >
          <span
            class="refresh"
          >
            <i
              class="el-icon-refresh-right"
              v-show="item.id==activeMenuId&&item.id==hoverBarId"
              @click.stop="refreshPage(item)"
            ></i>
          </span>
          <span>
            <i
              :class="activeMenuId === item.id ? 'el-icon-check' : null"
            ></i>
          </span>
          <div
            class="lc-flex-grow-1"
            :class="{'divided': item.divided === true}"
            :icon="activeMenuId === item.id ? 'el-icon-check' : null"
            @click.stop="barItemClick(item)"
          >{{ item.title }}</div>
          <span class="close">
            <i
              class="el-icon-close"
              v-show="item.id==hoverBarId" @click.stop="closeBarItem(item)"
            ></i>
          </span>
        </div>
        
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {},
  data() {
    return {
      hoverBarId: null,
      visitedViewsMaxlength: 8,
      fixBarList: [
        {
          title: '关闭全部',
          type: 'all',
          divided: false,
          button: true // 按钮
        },
        {
          title: '关闭其他页',
          type: 'other',
          divided: false,
          button: true
        },
        {
          title: '关闭当前页',
          type: 'self',
          divided: false,
          button: true
        },
        {
          title: '',
          type: '',
          divided: true,
          button: true
        },
      ]
    };
  },
  watch: {},
  computed: {
    ...mapState("navBar", {
      visitedViews: (state) => state.visitedViews,
      activeMenuId: (state) => state.activeMenuId,
    }),
    showBarList() {
      if (this.visitedViews.length > 8) {
        return this.visitedViews.slice(0, 7);
      } else {
        return [...this.visitedViews];
      }
    },
    hiddenBarList() {
      if (this.visitedViews.length > 8) {
        const visitedViews = this.visitedViews.slice(7, this.visitedViews.length);
        // visitedViews[0].divided = true
        return visitedViews
      } else {
        return [];
      }
    },
  },
  mounted() {
    
  },
  methods: {
    handleMouseenter(item) {
      this.hoverBarId = item.id;
    },
    handleMouseleave() {
      this.hoverBarId = null;
    },
    handleCommand(command) {
      // console.log(command)
      if (command.button) {
        if(command.type === 'all') {
          this.closeAll()
        } else if(command.type === 'other') {
          this.closeOtherItem(this.visitedViews.find( v => v.id === this.activeMenuId))
        } else if(command.type === 'self') {
          this.closeBarItem(this.visitedViews.find( v => v.id === this.activeMenuId))
        }
        return
      }
      // this.$store.commit("navBar/RESET_ACTIVE_MENU", command);
      this.barItemClick(command);
    },
    barItemClick(item) {
      this.$store.commit("navBar/SET_ACTIVE_MENU_ID", item.id);
      this.$router.push({ path: item.url });
    },
    closeBarItem(item) {
      this.$store.commit("navBar/DELETE_VIEW", item);
      this.$store.commit("navBar/DEL_CACHED_VIEW", item);
    },
    refreshPage(item) {
      this.$store.commit("navBar/DEL_CACHED_VIEW", item);
      this.$store.commit("navBar/SET_REFRESH_PAGE", item.url);
      
      this.$router.replace({
        path: "/common/emptyPage",
      });
    },
    closeAll() {
      this.$store.commit("navBar/DELETE_ALL");
      this.$store.commit("navBar/DEL_ALL_CACHED_VIEWS");

    },
    closeOtherItem(item) {
      this.$store.commit("navBar/DELETE_OTHER", item);
      this.$store.commit("navBar/DEL_OTHERS_CACHED_VIEWS", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: $navBar-color;
  .bar-item {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #114b72;
    border-right: 1px solid #fff;
    &:first-child {
      border-left: 1px solid #ccc;
    }
    cursor: pointer;
    padding: 0 4px;
    .content {
      height: 16px;
      font-size: 12px;
      color: #fff;
      padding: 0 30px 0 20px;
      // border-left: 1px solid #03395c;
      box-sizing: border-box;
      display: flex;
      position: relative;
      .refresh {
        position: absolute;
        top: 1px;
        left: 0;
        // height: 100%;
        // display: flex;
        // align-items: center;
        // margin-right: 4px;
        .el-icon-refresh-right {
          font-size: 14px;
          color: #fff;
        }
      }
      .close {
        position: absolute;
        right: 0;
        // height: 100%;
        // display: flex;
        // align-items: center;
        // margin-left: 10px;
        .el-icon-close {
          font-size: 14px;
          color: #fff;
        }
        .close:hover {
          color: pink;
        }
      }
      .bar-text {
        font-size: 12px;
        color: #fff;
      }
    }
  }
  .dropdownMore {
    width: 50px;
    margin-left: 10px;
    cursor: pointer;
    .el-dropdown-link {
      font-size: 12px;
      color: #fff;
      
    }
  }
  .active_menu {
    
    // background-color: #3379CC;
    background-color: $primary-color;
    color: #fff;
  }
  .hover-background{
    background-color: $submenu-active-color;
  }
  
}
.hidden-item {
  // width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .refresh {
    position: absolute;
    // top: 1px;
    left: 0;
    // height: 100%;
    // display: flex;
    // align-items: center;
    // margin-right: 4px;
    .el-icon-refresh-right {
      font-size: 14px;
      color: $primary-color;
    }
  }
  .close {
    position: absolute;
    right: 0;
    // height: 100%;
    // display: flex;
    // align-items: center;
    // margin-left: 10px;
    .el-icon-close {
      font-size: 14px;
      color: $primary-color;
    }
    .close:hover {
      color: pink;
    }
  }
  .divided {
    border-top: 1px solid $primary-color;
  }
}
</style>
