import Cookie from 'js-cookie'
import { loginByUserName, loginByPhone, getUserInfo } from '@/api/login'

const state = () => ({
  get id() {
    return Cookie.get('hyl-admin-id')
  },
  get realName() {
    return Cookie.get('hyl-admin-realname')
  },
  get userType() {
    return Cookie.get('hyl-admin-usertype')
  },
  get token() {
    return Cookie.get('hyl-admin-token')
  },
  set token(value) {
    if (!!value) {
      Cookie.set('hyl-admin-token', value)
    } else {
      // 清空当前所有域名下的token
      Cookie.set('hyl-admin-token', '')
      Cookie.remove('hyl-admin-token')
    }
  },
  set id(value) {
    if (!!value) {
      Cookie.set('hyl-admin-id', value)
    } else {
      // 清空当前所有域名下的用户id
      Cookie.set('hyl-admin-id', '')
      Cookie.remove('hyl-admin-id')
    }
  },

  set realName(value) {
    if (!!value) {
      Cookie.set('hyl-admin-realname', value)
    } else {
      // 清空当前所有域名下的用户姓名
      Cookie.set('hyl-admin-realname', '')
      Cookie.remove('hyl-admin-realname')
    }
  },

  set userType(value) {
    if (!!value) {
      Cookie.set('hyl-admin-usertype', value)
    } else {
      // 清空当前所有域名下的用户类型
      Cookie.set('hyl-admin-usertype', '')
      Cookie.remove('hyl-admin-usertype')
    }
  },

  get userInfo() {
    return JSON.parse(window.localStorage.getItem('userInfo') || '{}')
  },
  set userInfo(val) {
    window.localStorage.setItem('userInfo', JSON.stringify(val || []))
  },
  get roleMenu() {
    return JSON.parse(window.localStorage.getItem('roleMenu') || '[]')
  },
  set roleMenu(val) {
    window.localStorage.setItem('roleMenu', JSON.stringify(val || []))
  },
  get buttonPermission() {
    return JSON.parse(window.localStorage.getItem('buttonPermission') || '{}')
  },
  set buttonPermission(val) {
    window.localStorage.setItem('buttonPermission', JSON.stringify(val || {}))
  }
})
const mutations = {
  // 设置token
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_ID(state, id) {
    state.id = id
  },
  SET_REALNAME(state, realName) {
    state.realName = realName
  },
  SET_USERTYPE(state, userType) {
    state.userType = userType
  },
  // 用户登出
  LOGOUT(state) {
    state.token = null
  },
  // 设置用户信息
  SET_USER_INFO(state, val) {
    state.userInfo = val
  },
  // 设置权限菜单
  SET_ROLE_MENU(state, val) {
    state.roleMenu = val
  },
  // 设置权限按钮
  SET_BUTTON_PERMISSION(state, val) {
    state.buttonPermission = val
  }
}

const actions = {
  async login({ commit }, form) {
    // 请求登录接口
    if (form.pwdLogin) {
      let params = {
        account: form.account,
        password: form.password
      }
      return new Promise((resolve, reject) => {
        loginByUserName(params).then(res => {
          if (res.code === 200) {
            commit('SET_TOKEN', res.data)
            getUserInfo().then(response => {
              const data = response.data
              commit('SET_ID', data.id)
              commit('SET_REALNAME', data.name)
              // commit('SET_USERTYPE', '工地老板')
              commit('SET_USER_INFO', data)
              return res
            })
          }
          return resolve(res)
        })
      })
    } else {
      let params = {
        phone: form.phone,
        code: form.code
      }
      return new Promise((resolve, reject) => {
        loginByPhone(params).then(res => {
          if (res.code === 200) {
            commit('SET_TOKEN', res.data)
            getUserInfo().then(response => {
              const data = response.data
              commit('SET_ID', data.id)
              commit('SET_REALNAME', data.name)
              commit('SET_USER_INFO', data)
              return res
            })
          } 
          return resolve(res)
        })
      })
    }
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit('LOGOUT')
      window.location.reload()
      resolve()
    })
  }
}

export default {
  state,
  mutations,
  actions
}
